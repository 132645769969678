import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "margin-dialog-body" } },
    [
      _c(
        VContainer,
        [
          _c(
            VRow,
            [
              _c(
                VCol,
                {
                  staticClass: "mx-auto text-center",
                  attrs: { cols: "12", sm: "12", md: "3" }
                },
                [
                  _c(
                    VCardText,
                    {
                      staticClass:
                        "grey--text font-weight-bold text-center display-1",
                      attrs: { sm: "3", md: "12" }
                    },
                    [
                      _c(
                        VIcon,
                        {
                          staticClass: "ma-1 pa-1 pt-8",
                          attrs: { color: "grey", size: "180" }
                        },
                        [_vm._v(" mdi-lightbulb ")]
                      ),
                      _vm._v(" Antes de começar ")
                    ],
                    1
                  ),
                  _c(
                    VCardText,
                    {
                      staticClass:
                        "grey--text font-weight-regular text-center subtitle",
                      attrs: { sm: "3", md: "12" }
                    },
                    [
                      _vm._v(
                        "Baixe o modelo de planilha que disponibilizamos no botão abaixo: "
                      )
                    ]
                  ),
                  _c(
                    VBtn,
                    {
                      staticClass: "white--text",
                      attrs: {
                        color: "grey",
                        rounded: "",
                        "x-large": "",
                        depressed: ""
                      },
                      on: { click: _vm.telaDicas }
                    },
                    [_vm._v("Dicas de Upload")]
                  )
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "mx-auto my-auto",
                  attrs: { cols: "12", sm: "12", md: "3" }
                },
                [
                  _c(
                    VCardText,
                    {
                      staticClass:
                        "grey--text font-weight-black display-1 mt-4",
                      attrs: { sm: "3", md: "12" }
                    },
                    [_vm._v(" 1.Posso utilizar outro template para upload?")]
                  ),
                  _c(
                    VCardText,
                    {
                      staticClass: "grey--text font-weight-regular",
                      attrs: { sm: "3", md: "12" }
                    },
                    [
                      _vm._v(
                        "Nosso sistema possui campos mapeados, por isso não é possível utilizar outro arquivo ou um novo template para upload. Caso utilizar outro arquivo, esse arquivo poderá não ser compatível com o sistema."
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "mx-auto my-auto",
                  attrs: { cols: "12", sm: "12", md: "3" }
                },
                [
                  _c(
                    VCardText,
                    {
                      staticClass:
                        "grey--text font-weight-black display-1 mt-4 justify-start",
                      attrs: { sm: "3", md: "12" }
                    },
                    [_vm._v(" 2. Como devo preencher as colunas da planilha?")]
                  ),
                  _c(
                    VCardText,
                    {
                      staticClass:
                        "grey--text font-weight-regular justify-start",
                      attrs: { "justify-center": "", sm: "3", md: "12" }
                    },
                    [
                      _vm._v(
                        "Após baixar o template modelo, siga as instruções que existem em cada coluna. Fique atento a formatação de algumas colunas como Datas e documentos, pois possuem formatação correta."
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "12", sm: "12", md: "3" } },
                [
                  _c(
                    VCard,
                    {
                      attrs: {
                        color: "primary",
                        height: "500",
                        shaped: "",
                        outlined: ""
                      }
                    },
                    [
                      _c(
                        VCardText,
                        {
                          staticClass:
                            "white--text font-weight-bold text-center display-1",
                          attrs: { sm: "3", md: "12" }
                        },
                        [
                          _c(
                            VIcon,
                            {
                              staticClass: "ma-1 pa-1 pt-8",
                              attrs: { color: "white", size: "400%" }
                            },
                            [_vm._v(" download ")]
                          ),
                          _c(
                            VBtn,
                            {
                              attrs: {
                                color: "white",
                                rounded: "",
                                "x-large": "",
                                depressed: "",
                                href: _vm.link_modelo
                              }
                            },
                            [_vm._v("Baixar Template")]
                          )
                        ],
                        1
                      ),
                      _c(
                        VCardText,
                        {
                          staticClass:
                            "white--text font-weight-regular text-center subtitle",
                          attrs: { sm: "3", md: "12" }
                        },
                        [
                          _vm._v(
                            "Clique aqui para fazer o download do arquivo template XLS "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.dialogManual
        ? _c(
            VDialog,
            {
              attrs: {
                "max-width": "1200px",
                persistent: "",
                color: "background",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.dialogManual,
                callback: function($$v) {
                  _vm.dialogManual = $$v
                },
                expression: "dialogManual"
              }
            },
            [
              _c(
                VCard,
                {},
                [
                  _c(
                    VToolbar,
                    { attrs: { flat: "", dark: "", color: "primary" } },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: { icon: "", dark: "" },
                          on: { click: _vm.closeDialog }
                        },
                        [_c(VIcon, [_vm._v("mdi-close")])],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12", sm: "12", align: "left" } },
                        [_c(VToolbarTitle, [_vm._v("Manual de Upload")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    VCardText,
                    [
                      _c("ManualUpload", {
                        attrs: { dialogManual: _vm.dialogManual },
                        on: { fecharDialogManual: _vm.closeDialog }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }