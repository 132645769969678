import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ma-3", attrs: { id: "margin-dialog-body" } },
    [
      _c(
        VDialog,
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.dialogConfirmacao,
            callback: function($$v) {
              _vm.dialogConfirmacao = $$v
            },
            expression: "dialogConfirmacao"
          }
        },
        [
          _c(
            VCard,
            [
              _c(VCardTitle, { staticClass: "headline primary--text" }, [
                _vm._v("Deseja realmente confirmar a Remessa?")
              ]),
              _c(
                VCardText,
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.dialogResponse,
                      expression: "!dialogResponse"
                    }
                  ]
                },
                [
                  _vm._v(
                    "Ao confirmar a remessa você concorda com o encaminhamento dos títulos á protesto na próxima janela de envio!"
                  )
                ]
              ),
              _c(
                VCardText,
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.dialogResponse,
                      expression: "dialogResponse"
                    }
                  ]
                },
                [_vm._v("Remessa de títulos confirmada com sucesso!")]
              ),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.dialogResponse,
                          expression: "!dialogResponse"
                        }
                      ],
                      attrs: {
                        color: "green darken-1",
                        text: "",
                        "data-cy": "confirmarRemessa"
                      },
                      on: { click: _vm.aprovacaoRemessabtn }
                    },
                    [_vm._v("Sim")]
                  ),
                  _c(
                    VBtn,
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.dialogResponse,
                          expression: "!dialogResponse"
                        }
                      ],
                      attrs: { color: "red darken-1", text: "" },
                      on: { click: _vm.fechaConfirmacao }
                    },
                    [_vm._v("Não")]
                  ),
                  _c(
                    VBtn,
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.dialogResponse,
                          expression: "dialogResponse"
                        }
                      ],
                      attrs: { color: "green darken-1", text: "" },
                      on: { click: _vm.fechaConfirmacao }
                    },
                    [_vm._v("Ok!")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.dialogCancelamentoArquivo,
            callback: function($$v) {
              _vm.dialogCancelamentoArquivo = $$v
            },
            expression: "dialogCancelamentoArquivo"
          }
        },
        [
          _c(
            VCard,
            [
              _c(VCardTitle, { staticClass: "headline primary--text" }, [
                _vm._v("Deseja realmente cancelar a Remessa?")
              ]),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.dialogResponseCancelamentoArquivo,
                          expression: "!dialogResponseCancelamentoArquivo"
                        }
                      ],
                      attrs: {
                        color: "green darken-1",
                        text: "",
                        "data-cy": "confirmarCancelamento"
                      },
                      on: { click: _vm.cancelaArquivobtn }
                    },
                    [_vm._v("Sim")]
                  ),
                  _c(
                    VBtn,
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.dialogResponseCancelamentoArquivo,
                          expression: "!dialogResponseCancelamentoArquivo"
                        }
                      ],
                      attrs: { color: "red darken-1", text: "" },
                      on: { click: _vm.fechaCancelamento }
                    },
                    [_vm._v("Não")]
                  ),
                  _c(
                    VBtn,
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.dialogResponseCancelamentoArquivo,
                          expression: "dialogResponseCancelamentoArquivo"
                        }
                      ],
                      attrs: { color: "green darken-1", text: "" },
                      on: { click: _vm.fechaCancelamento }
                    },
                    [_vm._v("Ok!")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.dialogMensagemErro
        ? _c("Snackbar", {
            attrs: {
              mostrarSnackbar: _vm.dialogMensagemErro,
              corSnackbar: _vm.snackbarColor,
              mensagemSnackbar: _vm.mensagemErro
            },
            on: {
              fecharSnackbar: function($event) {
                _vm.dialogMensagemErro = false
              }
            }
          })
        : _vm._e(),
      _c(
        VDialog,
        {
          attrs: {
            persistent: "",
            transition: "dialog-bottom-transition",
            width: "450px"
          },
          model: {
            value: _vm.loading,
            callback: function($$v) {
              _vm.loading = $$v
            },
            expression: "loading"
          }
        },
        [
          _c(
            "div",
            { staticClass: "text-center", attrs: { width: "420px" } },
            [
              _c(
                VCard,
                [
                  _c(
                    VProgressCircular,
                    {
                      staticClass: "ma-12",
                      attrs: {
                        indeterminate: "",
                        size: "150",
                        color: "primary"
                      }
                    },
                    [_vm._v("Carregando...")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { attrs: { align: "left" } },
        [
          _c(
            VBtn,
            {
              staticClass: "my-2",
              attrs: {
                disabled: _vm.ativaDownload,
                width: "100%",
                dense: "",
                small: "",
                color: "primary"
              },
              on: { click: _vm.downloadRespostaRemessapre }
            },
            [
              _vm._v(" Resultado "),
              _c(VIcon, { staticClass: "ma-1" }, [_vm._v("cloud_download")])
            ],
            1
          ),
          _c(
            VBtn,
            {
              staticClass: "my-2",
              attrs: {
                disabled: _vm.ativaConfirmacao || _vm.disable,
                dense: "",
                small: "",
                width: "100%",
                color: "success",
                "data-cy": "confirmarUpload" + _vm.index
              },
              on: { click: _vm.aprovacaoConfirmacao }
            },
            [
              _vm._v(" Confirmar "),
              _c(VIcon, { staticClass: "ma-1" }, [_vm._v("done")])
            ],
            1
          ),
          _c(
            VBtn,
            {
              staticClass: "my-2",
              attrs: {
                disabled: _vm.ativaCancelamentoArquivo,
                dense: "",
                small: "",
                width: "100%",
                color: "error",
                "data-cy": "cancelarUpload" + _vm.index
              },
              on: { click: _vm.cancelarArquivo }
            },
            [
              _vm._v(" Cancelar "),
              _c(VIcon, { staticClass: "ma-1" }, [_vm._v("cancel")])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "white--text" },
        [
          _c(VTextarea, {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.existeErro,
                expression: "existeErro"
              }
            ],
            staticClass: "grey lighten-2 mt-2",
            attrs: {
              label: "Descrição do Erro",
              filled: "",
              rows: "5",
              readonly: "",
              color: "black"
            },
            model: {
              value: _vm.ds_erro_remessa,
              callback: function($$v) {
                _vm.ds_erro_remessa = $$v
              },
              expression: "ds_erro_remessa"
            }
          })
        ],
        1
      ),
      _vm.mostrarSnackbar
        ? _c("Snackbar", {
            attrs: {
              mostrarSnackbar: _vm.mostrarSnackbar,
              timeoutSnackbar: 6000,
              corSnackbar: _vm.corSnackbar,
              mensagemSnackbar: _vm.mensagemSnackbar
            },
            on: {
              fecharSnackbar: function($event) {
                _vm.mostrarSnackbar = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }