import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Loading", {
        attrs: { active: _vm.loadingAgrupador, "is-full-page": true }
      }),
      _c(
        VAlert,
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.mensagem,
              expression: "mensagem"
            }
          ],
          staticClass: "mt-2 mb-1",
          attrs: {
            dense: "",
            dismissible: "",
            outlined: "",
            type: _vm.tipoMensagem,
            border: "left"
          }
        },
        [_vm._v(_vm._s(_vm.mensagem))]
      ),
      _vm.mostrarConfirmacao
        ? _c("ConfirmacaoModal", {
            attrs: {
              showDialog: _vm.mostrarConfirmacao,
              tituloModal: _vm.tituloModal,
              textoModal: _vm.textoModal,
              btnConfirmarEmit: "confirmarUpload"
            },
            on: {
              confirmarUpload: _vm.confirmarUpload,
              fecharModal: function($event) {
                _vm.mostrarConfirmacao = false
              }
            }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "pa-3", attrs: { align: "center", justify: "center" } },
        [
          _c(
            VCard,
            { staticClass: "my-2", attrs: { align: "left", justify: "left" } },
            [
              _c(
                VCardText,
                { staticClass: "pa-3 font-weight-light white black--text" },
                [
                  _c(
                    VIcon,
                    { staticClass: "primary--text lighten-2 pa-2" },
                    [_vm._v(" cloud_upload ")]
                  ),
                  _vm._v("Títulos > Upload de Títulos ")
                ],
                1
              )
            ],
            1
          ),
          _c(
            VCard,
            [
              _c(
                VContainer,
                { attrs: { justify: "center" } },
                [
                  _c(
                    VRow,
                    { attrs: { align: "center" } },
                    [
                      _c(
                        VCol,
                        { attrs: { sm: "4" } },
                        [
                          _c(VFileInput, {
                            ref: "myFileInput",
                            staticClass: "mb-n7",
                            attrs: {
                              accept:
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xls, .xlsx, .xlsm",
                              label: "Selecione o arquivo (.xls/.xlsx/ .xlsm)",
                              rules: _vm.rules,
                              "show-size": "",
                              loading: _vm.loadingUpload,
                              disabled: _vm.desabilitaBoxEnvio,
                              chips: "",
                              outlined: "",
                              id: "fileInput",
                              "data-cy": "uploadInput"
                            },
                            on: { change: _vm.uploadFileReference },
                            model: {
                              value: _vm.file,
                              callback: function($$v) {
                                _vm.file = $$v
                              },
                              expression: "file"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        {
                          staticClass: "mx-auto",
                          attrs: { cols: "12", sm: "12", md: "2" }
                        },
                        [
                          _c(
                            VBtn,
                            {
                              staticClass: "white--text",
                              attrs: {
                                color: _vm.colorBtn,
                                large: "",
                                width: "100%",
                                loading: _vm.loadingUpload,
                                disabled: _vm.uploadOk,
                                "data-cy": "enviarUpload"
                              },
                              on: { click: _vm.upload }
                            },
                            [
                              _vm._v(" Enviar "),
                              _c(VIcon, { staticClass: "ml-2" }, [
                                _vm._v("cloud_upload")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { sm: "12", md: "6" } },
                        [
                          _c(
                            VTooltip,
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        VIcon,
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "ma-3",
                                              attrs: { color: "grey lighten-2" }
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v(" help ")]
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "Utilize esse template para fazer upload de títulos para o sistema da Recuperi"
                                )
                              ])
                            ]
                          ),
                          _c(
                            VBtn,
                            {
                              staticClass: "ma-2 white--text",
                              attrs: { color: _vm.colorBtn, fullwidth: "" },
                              on: { click: _vm.templateUpload }
                            },
                            [
                              _c(VIcon, { staticClass: "ma-2" }, [
                                _vm._v("cloud_download")
                              ]),
                              _vm._v(" Baixar Template ")
                            ],
                            1
                          ),
                          _c(
                            VBtn,
                            {
                              staticClass: "ma-2 white--text",
                              attrs: { color: _vm.colorBtn, fullwidth: "" },
                              on: { click: _vm.templateDicas }
                            },
                            [
                              _c(VIcon, { staticClass: "ma-2" }, [
                                _vm._v("info")
                              ]),
                              _vm._v(" Manual de Upload ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.mensagemAlerta,
                      expression: "mensagemAlerta"
                    }
                  ]
                },
                [
                  _c(VAlert, { attrs: { type: "error" } }, [
                    _vm._v(
                      " Apresentante inativo. Não é possível realizar o Upload de Títulos. "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        VAlert,
        {
          staticClass: "mx-3 mb-0 pa-3 font-weight-bold text-center",
          attrs: { border: "top", color: "primary", type: "info" },
          model: {
            value: _vm.snackbarApresentante,
            callback: function($$v) {
              _vm.snackbarApresentante = $$v
            },
            expression: "snackbarApresentante"
          }
        },
        [
          _vm._v(
            " Importante: você está logado em: " +
              _vm._s(_vm.nomeApresentante + " (" + _vm.cdApresentante + ")") +
              ", através do usuário: " +
              _vm._s(_vm.computedGetUserName) +
              ". "
          ),
          _c("br"),
          _vm._v(
            " Todas as alterações serão aplicadas ao apresentante " +
              _vm._s(_vm.nomeApresentante + " (" + _vm.cdApresentante + ")") +
              ". "
          )
        ]
      ),
      _vm.listaAgrupadoresValidos.length > 0
        ? _c(
            VAlert,
            {
              staticClass: "mx-3 mb-0 mt-3 pa-3 font-weight-bold text-center",
              attrs: { border: "top", color: "orange", type: "warning" }
            },
            [
              _vm._v(
                " Atenção: Ao preencher o Novo Campo Agrupador, certifique-se de que o código está preenchido corretamente "
              )
            ]
          )
        : _vm._e(),
      _vm.mostrarSnackbar
        ? _c("Snackbar", {
            attrs: {
              mostrarSnackbar: _vm.mostrarSnackbar,
              timeoutSnackbar: 6000,
              corSnackbar: _vm.corSnackbar,
              mensagemSnackbar: _vm.mensagemSnackbar
            },
            on: {
              fecharSnackbar: function($event) {
                _vm.mostrarSnackbar = false
              }
            }
          })
        : _vm._e(),
      _c(
        VDialog,
        {
          staticClass: "ma-3 pa-3 font-weight-bold",
          attrs: { border: "top", color: "error", type: "info" },
          model: {
            value: _vm.snackbarNumeroExcedido,
            callback: function($$v) {
              _vm.snackbarNumeroExcedido = $$v
            },
            expression: "snackbarNumeroExcedido"
          }
        },
        [
          _vm._v(
            " Erro: Número de títulos excede a quantidade permitida para upload via XLSX. Importante: O limite de títulos para upload através de um arquivo XLSX é de até 3 mil títulos (3000). Caso a quantidade de títulos for maior que 3 mil títulos, recomendamos dividir em 2 ou mais arquivos para o processamento. Para grandes quantidades, recomendamos a utilização de upload via API. "
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "pa-3", attrs: { align: "center", justify: "center" } },
        [
          _c(
            VCard,
            [
              _c(
                VContainer,
                [
                  _c(VDataTable, {
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.titulos,
                      options: _vm.options,
                      "item-class": _vm.itemRowBackground,
                      "server-items-length": _vm.totalTitulos,
                      loading: _vm.loading,
                      "footer-props": {
                        itemsPerPageOptions: [20, 100, 500]
                      },
                      "item-key": "id_arquivo",
                      "show-expand": "",
                      expanded: _vm.expanded,
                      "loading-text": "Pesquisando Histórico de Remessas...",
                      "no-data-text": "Nenhum registro encontrado",
                      "no-results-text": "Nenhuma Remessa Encontrada..."
                    },
                    on: {
                      "update:options": function($event) {
                        _vm.options = $event
                      },
                      "update:expanded": function($event) {
                        _vm.expanded = $event
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.contador_de_titulos_revisao",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              item.contador_de_titulos_revisao != 0
                                ? _c(
                                    "span",
                                    {
                                      style: { color: "red" },
                                      attrs: {
                                        title:
                                          "Este arquivo contém títulos em revisão que precisam ser corrigidos, favor acessar 'consulta de títulos'"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(item.contador_de_titulos_revisao)
                                      )
                                    ]
                                  )
                                : _c("span", { style: { color: "black" } }, [
                                    _vm._v(
                                      _vm._s(item.contador_de_titulos_revisao)
                                    )
                                  ])
                            ]
                          }
                        },
                        {
                          key: "item.st_arquivo",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              item.st_arquivo === "E"
                                ? _c(
                                    VBtn,
                                    {
                                      staticClass: "error ma-2",
                                      attrs: { depressed: "", small: "" }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        {
                                          staticClass: "ma-2",
                                          attrs: { small: "" }
                                        },
                                        [_vm._v(" mdi-alert-circle ")]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatStatusArquivo(item)
                                          ) +
                                          " "
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.st_arquivo === "P"
                                ? _c(
                                    VBtn,
                                    {
                                      staticClass: "grey lighten-4 ma-2",
                                      attrs: { depressed: "", small: "" }
                                    },
                                    [_vm._v(" NÃO CONFIRMADO ")]
                                  )
                                : _vm._e(),
                              item.st_arquivo === "F"
                                ? _c(
                                    VBtn,
                                    {
                                      staticClass: "success ma-2",
                                      attrs: { depressed: "", small: "" }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        {
                                          staticClass: "ma-2",
                                          attrs: { small: "" }
                                        },
                                        [_vm._v(" mdi-check ")]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatStatusArquivo(item)
                                          ) +
                                          " "
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.st_arquivo === "I"
                                ? _c(
                                    VBtn,
                                    {
                                      staticClass: "grey lighten-4 ma-2",
                                      attrs: { depressed: "", small: "" }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        {
                                          staticClass: "ma-2",
                                          attrs: { small: "" }
                                        },
                                        [_vm._v(" mdi-timer-sand ")]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatStatusArquivo(item)
                                          ) +
                                          " "
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.st_arquivo === "C"
                                ? _c(
                                    VBtn,
                                    {
                                      staticClass: "success ma-2 px-2 py-4",
                                      attrs: { depressed: "", small: "" }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        {
                                          staticClass: "my-2 mr-2",
                                          attrs: { small: "" }
                                        },
                                        [_vm._v(" mdi-check-circle ")]
                                      ),
                                      _vm._v(" CONFIRMADO EM: "),
                                      _c("br"),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatStatusConfirmado(item)
                                          ) +
                                          " "
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.st_arquivo === "D"
                                ? _c(
                                    VBtn,
                                    {
                                      staticClass: "ma-2",
                                      attrs: { depressed: "", small: "" }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        {
                                          staticClass: "ma-2",
                                          attrs: { small: "" }
                                        },
                                        [_vm._v(" cancel ")]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatStatusArquivo(item)
                                          ) +
                                          " "
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          }
                        },
                        {
                          key: "item.dt_criacao",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.formatDataCriacao(item)))
                              ])
                            ]
                          }
                        },
                        {
                          key: "expanded-item",
                          fn: function(ref) {
                            var headers = ref.headers
                            var item = ref.item
                            return [
                              _c("td", { attrs: { colspan: headers.length } }, [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "grey--text subtitle font-weight-black"
                                  },
                                  [
                                    _vm._v(
                                      "Nome do Arquivo: " +
                                        _vm._s(item.nm_arquivo_cliente)
                                    )
                                  ]
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.action",
                          fn: function(props) {
                            return [
                              _c("UploadTituloDetalhes", {
                                attrs: {
                                  index: props.index,
                                  detalhe_remessa: props.item
                                },
                                on: { reload: _vm.aplicarFiltros }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.dialog
        ? _c(
            VDialog,
            {
              attrs: {
                "max-width": "1200px",
                persistent: "",
                color: "background",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c(
                VCard,
                {},
                [
                  _c(
                    VToolbar,
                    { attrs: { flat: "", dark: "", color: "primary" } },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: { icon: "", dark: "" },
                          on: { click: _vm.closeDialog }
                        },
                        [_c(VIcon, [_vm._v("mdi-close")])],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12", sm: "12", align: "left" } },
                        [
                          _c(VToolbarTitle, [
                            _vm._v("Instruções de Upload")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    VCardText,
                    [
                      _c("DicasUpload", {
                        on: { fecharModal: _vm.closeDialog }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        VDialog,
        {
          attrs: {
            "max-width": "70%",
            color: "background",
            transition: "dialog-bottom-transition"
          },
          model: {
            value: _vm.dialogInicio,
            callback: function($$v) {
              _vm.dialogInicio = $$v
            },
            expression: "dialogInicio"
          }
        },
        [
          _c(VCard, {}, [
            _c(
              "div",
              { staticClass: "pa-8" },
              [
                _c(
                  VCardText,
                  {
                    staticClass: "font-weight-black display-1",
                    style: { color: _vm.primaryColorFont }
                  },
                  [_vm._v(" Antes de iniciar, faça o download do Template ")]
                ),
                _c(
                  VRow,
                  [
                    _c(
                      VCol,
                      { attrs: { sm: "6" } },
                      [
                        _c(VCardText, [
                          _vm._v(
                            "Para que possa realizar o upload corretamente, utilize nosso template que está disponível para download na tela de upload no canto superior."
                          )
                        ]),
                        _c(VCardText, [
                          _vm._v(
                            "Seu upload pode não funcionar caso não utilize nosso template, pois todos os campos são mapeados. "
                          )
                        ]),
                        _c(
                          VBtn,
                          {
                            staticClass: "ma-2",
                            attrs: {
                              color: "green darken-1 white--text",
                              "data-cy": "confirmarTemplate"
                            },
                            on: { click: _vm.desativaDialog }
                          },
                          [
                            _c(VIcon, { attrs: { dark: "" } }, [
                              _vm._v(" done ")
                            ]),
                            _vm._v(" OK, vou utilizar o Template ")
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      VCol,
                      { attrs: { sm: "6" } },
                      [
                        _c(VImg, {
                          attrs: {
                            src: require("@/assets/manual/print-botao_pb.png")
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm.dialogDicas
        ? _c(
            VDialog,
            {
              attrs: {
                "max-width": "1200px",
                persistent: "",
                scrollable: "",
                color: "background",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.dialogDicas,
                callback: function($$v) {
                  _vm.dialogDicas = $$v
                },
                expression: "dialogDicas"
              }
            },
            [
              _c(
                VCard,
                {},
                [
                  _c(
                    VCardTitle,
                    { staticClass: "pa-0" },
                    [
                      _c(
                        VToolbar,
                        { attrs: { flat: "", dark: "", color: "primary" } },
                        [
                          _c(
                            VBtn,
                            {
                              attrs: { icon: "", dark: "" },
                              on: { click: _vm.closeDialog }
                            },
                            [_c(VIcon, [_vm._v("mdi-close")])],
                            1
                          ),
                          _c(VToolbarTitle, [_vm._v("Manual de Upload")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(VDivider),
                  _c(
                    VCardText,
                    [
                      _c("ManualUpload", {
                        on: { fecharModal: _vm.closeDialog }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }